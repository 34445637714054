import { Checkbox, FormControlLabel, FormGroup, Grid } from "@material-ui/core";
import React from "react";
import { Line, LineChart, ResponsiveContainer, XAxis, YAxis } from "recharts";

export default function LineGraph({ data }) {
  const [state, setState] = React.useState({
    Nox: true,
    Sox: true,
    Co: true,
    O3: true,
    HC: true,
    "pm2.5": true,
    pm10: true,
    Humidity: true,
    Temperature: true,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <div>
      <ResponsiveContainer width="100%" aspect={4.0 / 1.0}>
        <LineChart style={{ marginTop: 40 }} data={data}>
          <XAxis width={60} dataKey="key" />
          <YAxis
            width={80}
            tickFormatter={(val) => `${val}`}
            domain={[0, 10000]}
          />
          {state["Nox"] ? (
            <Line
              animationDuration={600}
              dataKey="Nox"
              stroke="#0d6d00"
              strokeWidth={3}
              type="monotone"
            />
          ) : null}
          {state["Sox"] ? (
            <Line
              animationDuration={600}
              dataKey="Sox"
              stroke="#ff6d11"
              strokeWidth={3}
              type="monotone"
            />
          ) : null}
          {state["Co"] ? (
            <Line
              animationDuration={600}
              dataKey="Co"
              stroke="#633322"
              strokeWidth={3}
              type="monotone"
            />
          ) : null}
          {state["O3"] ? (
            <Line
              animationDuration={600}
              dataKey="O3"
              stroke="#90d6d3"
              strokeWidth={3}
              type="monotone"
            />
          ) : null}
          {state["HC"] ? (
            <Line
              animationDuration={600}
              dataKey="HC"
              stroke="#9d6d22"
              strokeWidth={3}
              type="monotone"
            />
          ) : null}
        </LineChart>
      </ResponsiveContainer>
      <Grid container justify="center">
        <FormGroup row>
          <FormControlLabel
            checked={state["Nox"]}
            onChange={handleChange}
            control={<Checkbox style={{ color: "#0d6d00" }} name="Nox" />}
            label="Nox"
          />
          <FormControlLabel
            checked={state["Sox"]}
            onChange={handleChange}
            control={<Checkbox style={{ color: "#ff6d11" }} name="Sox" />}
            label="Sox"
          />
          <FormControlLabel
            checked={state["Co"]}
            onChange={handleChange}
            control={<Checkbox style={{ color: "#633322" }} name="Co" />}
            label="Co"
          />
          <FormControlLabel
            checked={state["O3"]}
            onChange={handleChange}
            control={<Checkbox style={{ color: "#90d6d3" }} name="O3" />}
            label="O3"
          />
          <FormControlLabel
            checked={state["HC"]}
            onChange={handleChange}
            control={<Checkbox style={{ color: "#9d6d22" }} name="HC" />}
            label="HC"
          />
        </FormGroup>
      </Grid>
      <Grid item xs={12}>
        <ResponsiveContainer width="100%" aspect={4.0 / 1.0}>
          <LineChart style={{ marginTop: 40 }} data={data}>
            <XAxis width={60} dataKey="key" />
            <YAxis
              width={100}
              tickFormatter={(val) => `${val} \u03BCg/m\u00B3`}
              domain={[0, 1000]}
            />
            {state["pm2.5"] ? (
              <Line
                animationDuration={600}
                dataKey="pm2.5"
                stroke="#0d6dff"
                strokeWidth={3}
                type="monotone"
              />
            ) : null}
            {state["pm10"] ? (
              <Line
                animationDuration={600}
                dataKey="pm10"
                stroke="#3d6d11"
                strokeWidth={3}
                type="monotone"
              />
            ) : null}
          </LineChart>
        </ResponsiveContainer>
        <Grid container justify="center">
          <FormGroup row>
            <FormControlLabel
              checked={state["pm2.5"]}
              onChange={handleChange}
              control={<Checkbox style={{ color: "#0d6dff" }} name="pm2.5" />}
              label="pm2.5"
            />
            <FormControlLabel
              checked={state["pm10"]}
              onChange={handleChange}
              control={<Checkbox style={{ color: "#3d6d11" }} name="pm10" />}
              label="pm10"
            />
          </FormGroup>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <ResponsiveContainer width="100%" aspect={4.0 / 1.0}>
          <LineChart style={{ marginTop: 40 }} data={data}>
            <XAxis width={60} dataKey="key" />
            <YAxis domain={[0, 100]} tickFormatter={(val) => val + "%"} />
            {state["Humidity"] ? (
              <Line
                animationDuration={600}
                dataKey="humidity"
                stroke="#0d6dff"
                strokeWidth={3}
                type="monotone"
              />
            ) : null}
          </LineChart>
        </ResponsiveContainer>
        <Grid container justify="center">
          <FormGroup row>
            <FormControlLabel
              checked={state["Humidity"]}
              onChange={handleChange}
              control={
                <Checkbox style={{ color: "#0d6dff" }} name="Humidity" />
              }
              label="Humidity"
            />
          </FormGroup>
        </Grid>
      </Grid>
      <Grid container style={{ marginTop: 100 }}>
        <ResponsiveContainer width="100%" aspect={4.0 / 1.0}>
          <LineChart style={{ marginTop: 40 }} data={data}>
            <XAxis width={60} dataKey="key" />
            <YAxis
              type="number"
              tickFormatter={(val) => `${val} \xB0C`}
              domain={[0, 100]}
            />
            {state["Temperature"] ? (
              <Line
                animationDuration={600}
                dataKey="Temperature"
                stroke="#dd6d44"
                strokeWidth={3}
                type="monotone"
              />
            ) : null}
          </LineChart>
        </ResponsiveContainer>
        <Grid container justify="center">
          <FormGroup row>
            <FormControlLabel
              checked={state["Temperature"]}
              onChange={handleChange}
              control={
                <Checkbox style={{ color: "#dd6d44" }} name="Temperature" />
              }
              label="Temperature"
            />
          </FormGroup>
        </Grid>
      </Grid>
    </div>
  );
}
