import React, { useRef, useState } from 'react';
import { Grid, Paper, TextField, Button, CircularProgress, makeStyles } from '@material-ui/core';
import images from '../../../assets';
import { colors } from '../../../util/colors';

export default function Login(props) {
    const classes = styles();
    const passwordRef = useRef(null)
    const loginRef = useRef(null)

    const [errorText, toggleErrorText] = useState(false)

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [emailError, toggleEmailError] = useState(false)
    const [passwordError, togglePasswordError] = useState(false)
    const [loginLoading, setLoginLoading] = useState(false)
    const [invalidInputs, setInvalidInputs] = useState(false)
    const [pending, setPending] = useState(false)

    const handleEmailPress = event => {
        if (event.key === 'Enter') {
            passwordRef.current.focus();
        }
    }

    const handlePasswordPress = event => {
        if (event.key === 'Enter') {
            loginRef.current.click();
        }
    }

    const handleSubmit = async () => {
        if (!email || !password) {
            setPending(false)
            setInvalidInputs(false)
            toggleErrorText(true)
            if (!email) { toggleEmailError(true) }
            if (!password) { togglePasswordError(true) }
        }
        else {
            toggleErrorText(false)
            setLoginLoading(true)
            // var _obj = {
            //     email: email,
            //     password: password,
            // }
            props.history.push('dashboard')

        }
    }
    
    return (
        <Grid
            className={classes.root}
        >
            <Grid
                xl={4}
                lg={4}
                md={7}
                xs={10}
                spacing={10}
                container
                direction="column"
                justify="center"
                alignItems="center"
                alignContent="center"
                className={classes.container}
            >
                <img
                    className={classes.logo}
                    src={images.logo}
                    alt="logo"
                />
                <Paper
                    elevation={3}
                    className={classes.LoginComponent}
                >
                    <h1 className={classes.heading}>Login</h1>
                    <p className={classes.text}>Access to the most powerful global innovation hub</p>
                    {errorText && <p style={{ color: '#CC3300' }}>* All fields are required</p>}
                    {pending && <p style={{ color: '#CC3300' }}>* Please wait your account is pending with admin approval</p>}
                    <form className='loginForm'>
                        <TextField
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline
                                }
                            }}
                            error={emailError}
                            variant='outlined'
                            onKeyPress={handleEmailPress}
                            label='Email or Username'
                            autoFocus={true}
                            onChange={(e) => {
                                setEmail(e.target.value)
                                toggleEmailError(false)
                            }}
                            className={classes.inputFields}
                        />
                        <TextField
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline
                                }
                            }}
                            error={passwordError}
                            type='password'
                            variant='outlined'
                            onKeyPress={handlePasswordPress}
                            onChange={(e) => {
                                setPassword(e.target.value)
                                togglePasswordError(false)
                            }}
                            label='Password'
                            inputRef={passwordRef}
                            className={classes.inputFields}
                        />
                        <Grid
                            container
                            component='div'
                            direction='row'
                            justify='space-between'
                            className={classes.buttonContainer}
                        >
                            <Grid item>
                                <Button
                                    disabled={loginLoading}
                                    classes={{
                                        root: classes.loginButton,
                                        disabled: classes.loginButtonDisabled
                                    }}
                                    innerRef={loginRef}
                                    variant='contained'
                                    onClick={handleSubmit}
                                >
                                    Login
                            {loginLoading && <CircularProgress size={24} className={classes.loginLoader} />}
                                </Button>
                                {invalidInputs && <p style={{ fontSize: 10, color: '#F44336' }}>Invalid Username or Password</p>}

                            </Grid>
                            <Grid item >
                                <Button
                                    variant='text'
                                    disableRipple
                                    disabled={loginLoading}
                                    className={classes.forgetButton}
                                    onClick={() => props.history.push('forget-password')}
                                >
                                    Forget Password?
                            </Button>
                            </Grid>
                        </Grid>
                    </form>
                    <Button
                        variant='text'
                        disableRipple
                        disabled={loginLoading}
                        className={classes.registerButton}
                        onClick={() => props.history.push('register-company')}
                    >
                        Register a Company Account
                </Button>
                    <Button

                        variant='text'
                        disableRipple
                        disabled={loginLoading}
                        className={classes.registerButton}
                        onClick={() => props.history.push('Signup')}
                    >
                        Register a User Account
                </Button>
                </Paper>
            </Grid>
        </Grid>
    )
}



const styles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        paddingBottom: 100,
        alignItems: 'center',
        backgroundImage: `linear-gradient(to bottom, ${colors.buttonBlue + 'dd'}, ${colors.backgroundPurple + 'dd'} )`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",

    },
    logo: {
        marginTop: 30,
        marginBottom: 30,
        width: 150,
        height: 150,
        objectFit: 'contain'
    },
    LoginComponent: {
        padding: 30,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 12,
    },
    heading: {
        color: colors.black,
        margin: 0,
        marginTop: 8,
        marginBottom: 8,
    },
    text: {
        color: colors.black,
        margin: 0,
        marginTop: 8,
        marginBottom: 8,
    },
    inputFields: {
        fontFamily: 'Poppins',
        backgroundColor: colors.inputGray,
        marginTop: 20,
        outline: 'none',
        width: '100%',
        borderRadius: 12
    },
    notchedOutline: {
        borderWidth: '0px',
        borderRadius: 12
    },
    buttonContainer: {
        margin: 0,
        padding: 0,
        marginTop: 20,
    },
    loginButton: {
        textTransform: 'none',
        backgroundColor: colors.buttonBlue,
        color: colors.white,
        fontSize: 14,
        fontWeight: 'bold',
        height: 40,
        width: 120,
        marginBottom: 20,
        '&:hover': {
            backgroundColor: colors.buttonBlue + 'dd',
        },
        '&:disabled': {
            backgroundColor: colors.buttonBlue,
            color: colors.buttonBlue,
        }
    },
    loginLoader: {
        position: 'absolute',
        bottom: 8,
        right: '42%',
        color: colors.white
    },
    forgetButton: {
        textTransform: 'none',
        color: colors.borderGray,
        fontSize: 14,
        fontWeight: 'bold',
        height: 40,

    },
    registerButton: {
        textDecoration: 'underline',
        textAlign: 'left',
        textTransform: 'none',
        fontSize: 14,
        fontWeight: 'bold',
        margin: 0,
        height: 20,
        marginBottom: 10,
    }
}))