export const colors = {
    white: '#ffffff',
    black: '#000000',
    buttonBlue: '#2F5496',
    backgroundPurple: '#613577',
    inputGray: '#EEEEEE',
    borderGray: '#0000003D',
    shadowGray: '#00000029',
    text2Gray: '#ADAFCA',
    btn1: '#3E3F5E',
    dropdownGray: '#707070',
    badgeOrange: '#F3670A',
    headingBlack: '#1C1C1C',
    stepperOrange: '#FC4A00',
    featuredPink: '#DA1793',
    featuredPurple: '#6741DC',
    stepperGray: '#D5D5D5',
    newGreen: '#4CA80B',
    captionGray: '#A3A3A3',
    trendingBlue: '#007BFF',
    dividerGray: '#E1E1E1',
    footerBlack: '#222222',
    footerInputGray: '#AEAEAE',

    textPurple: '#5E3174',
    buttonGray: '#E6E3E3',
    textGreen: '#009D00',

    error: '#CC3300',

    boxShadow: '#744E8745'
}