import React from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import Login from "../containers/authentication/login";
import Dashboard from "../containers/dashboard";

export default function AppRouter() {
  return (
    <BrowserRouter>
      <Switch>
        <PublicRoute restricted={true} path="/" component={Login} exact />
        <PublicRoute exact path="/dashboard" component={Dashboard} />
      </Switch>
    </BrowserRouter>
  );
}

const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  return <Route {...rest} render={(props) => <Component {...props} />} />;
};
