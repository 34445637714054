import React, { Fragment } from "react";
// import { makeStyles } from '@material-ui/core/styles';
import { Grid } from "@material-ui/core";

const header = [
  { id: 1, title: "NOx (ppb)" },
  { id: 2, title: "SOx (ppb)" },
  { id: 3, title: "CO (ppm)" },
  { id: 4, title: "O3 (ppb)" },
  { id: 0, title: "HC (ppb)" },
  { id: 5, title: "PM 2.5 (μg/m³)" },
  { id: 6, title: "PM 10 (μg/m³)" },
  { id: 8, title: "Temperature (°C)" },
  { id: 7, title: "Humidity (%)" },
];

const CustomTable = ({ data }) => {
  return (
    <div style={{ height: 480, backgroundColor: "lightgray" }}>
      <Grid
        style={{
          display: "flex",
          flexGrow: 1,
          backgroundColor: "lightskyblue",
        }}
      >
        {header.map((item) => (
          <Grid
            key={item.id}
            item
            xs={12}
            style={{
              display: "flex",
              flex: 1,
              backgroundColor: "lightskyblue",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: 60,
              margin: 1,
            }}
          >
            <p style={{ textAlign: "center" }}>{item.title}</p>
          </Grid>
        ))}
      </Grid>
      {data
        ? data.map((dataItem, index) => {
            return (
              <Fragment key={dataItem[1] + index.toString()}>
                <Grid
                  style={{
                    display: "flex",
                    flexGrow: 1,
                    backgroundColor: "#fff",
                  }}
                >
                  {dataItem[2].map((item, index) => (
                    <Grid
                      key={Object.values(item)[0] + index.toString()}
                      item
                      xs={12}
                      style={{
                        display: "flex",
                        flex: 1,
                        backgroundColor: "#D3D3D3",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: 40,
                        margin: 1,
                      }}
                    >
                      <p>{Object.values(item)[0]}</p>
                    </Grid>
                  ))}
                </Grid>
              </Fragment>
            );
          })
        : null}
    </div>
  );
};

export default CustomTable;
