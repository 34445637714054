/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Chart from "../dashboard/Graph";
import { colors } from "../../util/colors";
import Table from "../dashboard/Table";
import { Button, Grid, Typography } from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Link } from "react-router-dom";

export default function Dashboard(props) {
  const wb = new WebSocket("wss://api.aqms-balochistan.com:8000/ws/polData/");
  const [chartData, setChartData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(formatDate(new Date()));

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  const handleDateChange = (date) => {
    const updatedDate = formatDate(date);
    setSelectedDate(updatedDate);
  };

  let dumpData = 0;

  useEffect(() => {
    wb.onmessage = (message) => {
      let response = JSON.parse(message.data);
      let updatedResponse = response.data;
      setChartData((prevData) => {
        let prev = prevData;
        let currentDate = new Date();
        let newSeconds = currentDate.getSeconds();
        let giveDate = false;
        if ((newSeconds >= 0 && newSeconds < 5) || prevData.length === 0) {
          giveDate = true;
        }
        dumpData = dumpData + 1;
        if (prevData.length > 30) prev.shift();

        return [
          ...prev,
          {
            key: giveDate
              ? `${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}`
              : "",
            Nox: Object.values(updatedResponse[2][0])[0],
            Sox: Object.values(updatedResponse[2][1])[0],
            Co: Object.values(updatedResponse[2][2])[0],
            O3: Object.values(updatedResponse[2][3])[0],
            HC: Object.values(updatedResponse[2][4])[0],
            "pm2.5": Object.values(updatedResponse[2][5])[0],
            pm10: Object.values(updatedResponse[2][6])[0],
            Temperature: Object.values(updatedResponse[2][7])[0],
            humidity: Object.values(updatedResponse[2][8])[0],
          },
        ];
      });

      setTableData((prevData) => {
        let prev = prevData;
        if (prevData.length > 9) prev.shift();
        return [...prev, updatedResponse];
      });
    };
    wb.onerror = (event) => {
      console.error("WebSocket error observed:", event);
    };
  }, []);

  return (
    <div
      style={{
        flexGrow: 1,
        backgroundColor: colors.backgroundPurple + "22",
        padding: 30,
        paddingBottom: 100,
      }}
    >
      <Typography
        variant="h2"
        style={{
          textAlign: "center",
          paddingBottom: 30,
          fontFamily: "Georgia",
        }}
      >
        Air Quality Monitoring System
      </Typography>
      <Grid
        container
        direction="row"
        alignItems="center"
        justify="flex-end"
        style={{ marginBottom: 30 }}
      >
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            style={{ marginRight: 20 }}
            margin="normal"
            id="date-picker-dialog"
            label="Get Data by date"
            format="MM/dd/yyyy"
            value={selectedDate}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
          <Button variant="contained" color="secondary">
            <Link
              to={`https://api.aqms-balochistan.com:8000/basic/fetch/${selectedDate}/`}
              target="_blank"
              onClick={(event) => {
                event.preventDefault();
                window.open(
                  `https://api.aqms-balochistan.com:8000/basic/fetch/${selectedDate}/`
                );
              }}
              style={{ color: "white", textDecoration: "none" }}
            >
              Get Data
            </Link>
          </Button>
        </MuiPickersUtilsProvider>
      </Grid>
      <Table data={tableData} />
      <Chart data={chartData} />
    </div>
  );
}
